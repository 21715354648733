html, body { height: 100%; }
body {
    display: flex;
    flex-flow: column;
    
    background-color: #e6f5ff;
}

.flex-1 { flex: 1}
.flex-2 { flex: 2}
.flex-3 { flex: 3}
.flex-grow-1 {
    flex-grow: 1;
}

.hidden {
    visibility: hidden;
}

.sp-screen {
    display: flex;
    flex-flow: column;
    //align-self: center;
    height: 100%;
    //max-width: 1300px;
    
    app-top-progress-bar {
        margin: 1.5em 1.875em;
    }
}

.sp-desktop {
    display: none !important;     
}

app-main-header {
    
    &.sticky {
        position: sticky;
        top: 0;
        background: #fff;
        z-index: 9;

        &.scrolled {
            box-shadow: 0 3px 6px #00000029;
        }

        &.scrolled-far {
            .title {
                //transition: font-size .2s;
                //font-size: 30px;
                //padding: 17px 0 17px 56px;
            }

            app-pure-main-header {
                margin: 0;
                .legend {
                    display: none !important;
                }
            }
        }

        
    }
}


.sp-wrapped {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100%;
    max-width: 1306px;
}

@media screen and (max-width: 799px) {
    app-footer-compact { display: none !important; }
}

@media screen and (min-width: 800px) {
    .sp-not-desktop { display: none !important; }
    .sp-desktop { display: flex !important; }

    .sp-padding {
        padding: 0 10px;        
    }
    
    app-main-panel {
        height: auto;
        width: auto;
        min-width: 464px;
        border-radius: 1.875em;
        //margin: 2em auto;
    }
}
@media screen and (max-width: 1299px) {
    .sp-wrapped {
        width: auto;
        margin-left: 60px !important;
        margin-right: 60px !important;
    }    
    .desktop-layout {
        display: none !important;
    }
}
@media screen and (max-width: 1000px) {
    .sp-wrapped {
        margin-left: 40px !important;
        margin-right: 40px !important;
    }
}

@media screen and (max-width: 800px) {
    /*app-main-header {
        display: none !important;
    }*/
    .sp-wrapped {
        border-radius: 0 !important;
        //margin-top: 43px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;

        &.sp-padding {
            margin-left: 10px !important;
            margin-right: 10px !important;
        }
        
        app-main-panel {
            border-radius: 0;
            padding: 20px 10px;
        }
        
    }
}

@media screen and (min-width: 1300px) {
    .mobile-layout { 
        display: none !important; 
    }
}
@media screen and (max-width: 700px) {
    .tablet-layout {
        display: none !important;
    }
}
