.sp-selectable {
    cursor: pointer;
    font-size: 1.1875em;
    padding: 18px 13px;
    border-radius: 16px;
    color: #00376F;
    margin-bottom: .625em;

    background-color: #fff;
    
    border: 2px solid transparent;
    transition: border-color .2s, background-color .2s;
    box-shadow: 0 3px 6px #00000029;

    input[type=radio], input[type=checkbox] {
        margin: 0 .5em 0 1em;
        accent-color: #00376F;
        height: 22px;
        width: 22px;
    }
    
    
    input[type=date], input[type=time] {
        font-weight: 500;
        padding-left: 0;
        margin: 0;
        background-color: #fff;
        color: #00376F;
        width: 100%;
        outline: none !important;
    }
    
    .material-icons {
        padding: .85em;
        
        &.arrow {
            color: #B5B5B5;
            margin-left: auto;
            padding: 0;
        }
        
    }
    
    
    label {
        padding: .85em 1.4em .85em 0;

        flex: 1;
        font-size: 19px;
        font-weight: 500;
        margin: 0;
    }
    
    &:hover {
        //background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(230,242,255,1) 100%);
        background-color: #EEF9FF;
        border-color: #5CC0FF;
        //border-color: #00376F;
        
        .arrow {
            transition: color 0.2s;
            color: #000;
        }
    }

    &.selected {
        background-color: #EEF9FF;
    }

    
}